import { Box, Typography, useMediaQuery } from "@mui/material";
import { SelectRow } from "components/layouts/SelectRow";
import { PricingSelect } from "components/PricingSelect";
import { useTranslation } from "react-i18next";
import { DataContent } from "./DataContent";
import { TariffInfo } from "../TariffInfo";
import { MobileTariffTitle } from "../TariffsTitle";

export const DataSelector = ({
  dataset,
  onClick,
  showInfo,
  dataOffers = [],
  ...props
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <SelectRow
      title={
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body_bold">
            {t("funnel.tariffs.mobile.how_many_gigabytes")}
          </Typography>
          {showInfo && (
            <TariffInfo
              type="mobile"
              title={
                <MobileTariffTitle minutes={props.minutes} data={props.data} />
              }
              {...props}
            />
          )}
        </Box>
      }
      maxRows={isMobile ? 5 : 7}
    >
      {dataset.map((mobileTariff) => (
        <PricingSelect
          content={
            <DataContent
              data={mobileTariff.data}
              isSelected={mobileTariff.isSelected}
              onClick={() => onClick(mobileTariff)}
              isOffer={dataOffers.some(
                (dataOffer) => dataOffer === mobileTariff.data
              )}
            />
          }
          isSelected={mobileTariff.isSelected}
          key={mobileTariff.data}
        />
      ))}
    </SelectRow>
  );
};
