import { Box, Dialog, useMediaQuery } from "@mui/material";
import { CautionChip } from "components/Chips";

export const CautionModal = ({ isOpen, children }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Dialog open={isOpen}>
      <Box
        sx={{
          p: isMobile ? "16px" : "48px",
          display: "flex",
          flexDirection: "column",
          bgcolor: "sand.main",
          gap: 3,
        }}
      >
        <CautionChip sx={{ maxWidth: 100 }} />
        {children}
      </Box>
    </Dialog>
  );
};
