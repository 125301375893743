import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { Button } from "components/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { CautionModal } from "components/modals/Caution";

export const ModalAdviceOffer = ({
  onConfirm,
  onClose,
  form__id,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <CautionModal {...props}>
      <Typography variant="body_bold">
        {t("funnel.shared.advice_offer_modal.title")}
      </Typography>
      <Button
        variant="secondary"
        form={form__id}
        type="submit"
        onClick={onConfirm}
        endIcon={<ArrowForwardIcon />}
      >
        {t("funnel.shared.advice_offer_modal.accept")}
      </Button>
      <Button onClick={onClose} endIcon={<ArrowForwardIcon />}>
        {t("funnel.shared.advice_offer_modal.decline")}
      </Button>
    </CautionModal>
  );
};
