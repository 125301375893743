import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { RequestError } from "components/RequestError";
import { Spinner } from "components/Spinner";
import { useFaqsPages } from "hooks/queries/useFaqs";
import { Header } from "./Header";
import { Divider } from "components/Divider";
import "../../../faqs.css";

export const Highlights = ({ highlighted, sx }) => {
  const {
    isLoading: isLoadingPage,
    error: pageLoadError,
    data: pages,
    refetch: refetchPages,
  } = useFaqsPages({ highlighted });
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        py: 6,
        gap: 3,
        background: theme.palette.grey[100],
        ...sx,
      })}
    >
      <Header />
      {isLoadingPage ? (
        <Spinner />
      ) : pageLoadError ? (
        <RequestError onClick={refetchPages} />
      ) : (
        pages.map((page) => (
          <Box key={page.ref}>
            <Accordion disableGutters sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={(theme) => ({ color: theme.palette.grey[900] })}
                  />
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography variant="body_bold">{page.name}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 3 }}>
                <Divider sx={{ mx: -3, mt: -3, mb: 3 }} />
                <div
                  className="faqs-content"
                  dangerouslySetInnerHTML={{ __html: page.content }}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        ))
      )}
    </Box>
  );
};
