import { Box, Typography } from "@mui/material";
import { Questioning } from "components/icons/Questioning";
import { Modal } from "components/layouts/Modal";
import { RequestError } from "components/RequestError";
import { Spinner } from "components/Spinner";
import { useFaqsPage } from "hooks/queries/useFaqs";
import { useTranslation } from "react-i18next";

import "../../faqs.css";

export const ModalTariffMoreInfo = ({ pageRef, title, ...props }) => {
  const { t } = useTranslation();
  const {
    isLoading: isLoadingPage,
    error: pageLoadError,
    data: { content: pageContent = "Content Page" } = {},
    refetch,
  } = useFaqsPage({ pageRef, enabled: !!pageRef });
  return (
    <Modal title={t("tariff_info.modal.title")} {...props}>
      <Box
        sx={(theme) => ({
          p: [3, 8],
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 3,
          background: theme.palette.grey[100],
        })}
      >
        <Questioning />
        <Typography variant="body_bold">
          {t("tariff_info.modal.sub_title")}
        </Typography>

        {isLoadingPage ? (
          <Box sx={{ width: "500px" }}>
            <Spinner />
          </Box>
        ) : pageLoadError ? (
          <RequestError onClick={refetch} />
        ) : (
          <>
            <Typography variant="h2" sx={{ mt: 3 }}>
              {title}
            </Typography>
            <div
              className="faqs-content"
              dangerouslySetInnerHTML={{ __html: pageContent }}
            />
          </>
        )}
      </Box>
    </Modal>
  );
};
