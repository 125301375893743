import { Box } from "@mui/material";
import { Button } from "ancient/components/Button";
import { useApplicationContext } from "hooks/useApplicationContext";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { AdditionalDataModal } from "../modal/AdditionalDataModal";
import { ChangeTariffModal } from "../modal/ChangeTariffModal";
import { ChangeTariffModalBA } from "../modal/ChangeTariffModalBA";
import { Tiles } from "components/layouts/Tiles";

export const SubscriptionOperationsTab = ({ ...subscription }) => {
  const { available_operations, subscription_type } = {
    ...subscription,
  };
  const { t } = useTranslation();
  const closeModal = () => setCurrentModal(null);

  const [currentModal, setCurrentModal] = useState(null);

  const { currentUser } = useApplicationContext();
  const { profile_banned_actions: bannedActions } = currentUser;
  /*
   * Partner banned actions
   */
  const {
    is_mobile_tariff_change: isChangeTariffBanned,
    is_mobile_one_shot: isAdditionalDataBanned,
  } = bannedActions;
  /*
   * Available operations associated to contract
   */
  const isChangeFiberLandline = available_operations.includes(
    "ChangeTariffFiberLandline"
  );
  const isChangeFiber =
    available_operations.includes("ChangeTariffFiberOutLandline") ||
    isChangeFiberLandline;
  const isChangeMobile = available_operations.includes("ChangeTariffMobile");
  const isOneShotMobile = available_operations.includes("AddOneShotMobile");
  const NoOperationsAvailable = () => {
    const { t } = useTranslation();
    return <Box mt={3}>{t("subscriptions.detail.no_operation_available")}</Box>;
  };
  const BroadbandOperations = () => {
    const isChangeTariffBA = isChangeFiber && !isChangeTariffBanned;
    return isChangeTariffBA ? (
      <Tiles spacing={4}>
        <Button onClick={() => setCurrentModal("change_tariff_BA")}>
          {t(
            "subscriptions.detail.change_tariff_BA" +
              (isChangeFiberLandline ? "_with_landline" : "_without_landline")
          )}
        </Button>
      </Tiles>
    ) : (
      <NoOperationsAvailable />
    );
  };
  const MobileOperations = () => {
    const isChangeTariffMobile = isChangeMobile && !isChangeTariffBanned;
    const isAdditionalDataMobile = isOneShotMobile && !isAdditionalDataBanned;
    const hasOperations = isChangeTariffMobile || isAdditionalDataMobile;
    return hasOperations ? (
      <Tiles spacing={4}>
        {isChangeTariffMobile && (
          <Button
            onClick={() => {
              setCurrentModal("change_tariff");
            }}
          >
            {t("subscriptions.detail.change_tariff")}
          </Button>
        )}

        {isAdditionalDataMobile && (
          <Button
            onClick={() => {
              setCurrentModal("additional_data");
            }}
          >
            {t("subscriptions.detail.additional_data")}
          </Button>
        )}
      </Tiles>
    ) : (
      <NoOperationsAvailable />
    );
  };
  return (
    <Fragment>
      <ChangeTariffModal
        isOpen={currentModal === "change_tariff"}
        onClose={closeModal}
        subscription={subscription}
      />
      <AdditionalDataModal
        isOpen={currentModal === "additional_data"}
        onClose={closeModal}
        subscription={subscription}
      />
      <ChangeTariffModalBA
        isOpen={currentModal === "change_tariff_BA"}
        onClose={closeModal}
        subscription={subscription}
      />
      <Box mt={3}>
        {subscription_type === "mobile" && <MobileOperations />}
        {subscription_type === "broadband" && <BroadbandOperations />}
        {subscription_type === "switchboard" && <NoOperationsAvailable />}
      </Box>
    </Fragment>
  );
};
