import { Typography, Accordion, AccordionSummary } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { noop } from "lib/fn/noop";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionDetailsLayout } from "./AccordionDetailsLayout";

const useStyles = makeStyles(() => ({
  AccordionSummary: {
    textTransform: "uppercase",
  },
  Accordion: {
    boxShadow: "none",
  },
}));

export const AccordionLayout = ({
  accordionTitle,
  dataQuery = noop,
  queryKey,
  listItem,
  querySettings,
}) => {
  const styles = useStyles();

  return (
    <Accordion
      className={styles.Accordion}
      slotProps={{
        transition: { unmountOnExit: true },
      }}
    >
      <AccordionSummary
        className={styles.AccordionSummary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="button" color="textSecondary">
          {accordionTitle}
        </Typography>
      </AccordionSummary>

      <AccordionDetailsLayout
        dataQuery={dataQuery}
        queryKey={queryKey}
        listItem={listItem}
        querySettings={querySettings}
      />
    </Accordion>
  );
};
