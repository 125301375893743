import { Alert, Typography } from "@mui/material";
import { Stack } from "ancient/components/layouts/Stack";
import { Form } from "ancient/components/LegacyForm";
import { LegacyTextField } from "ancient/components/LegacyTextField";
import { Button } from "components/Button";
import { FullScreenCenteredLayout } from "components/layouts/FullScreenCenteredLayout";

import { Spinner } from "components/Spinner";
import { getInvoiceByToken } from "lib/api/invoices";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export const Inovice = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState();
  const [username, setUser] = useState("");

  const { token } = useParams();
  const { t } = useTranslation();

  async function onSubmit(event) {
    setIsLoading(true);
    await getInvoiceByToken({
      token,
      username: `es${username.replace(/^es/i, "")}`.toUpperCase(),
    })
      .then(async ({ data }) => {
        // Create a Blob from the response data
        const base64Pdf = await fetch(
          `data:application/pdf;base64,${data.pdf}`
        );
        const pdfBlob = await base64Pdf.blob();
        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(pdfBlob);
        // Create a temporary <a> element to trigger the download
        const tempLink = document.createElement("a");
        tempLink.href = url;
        // Set the filename for the downloaded file
        tempLink.setAttribute("download", `${data.name}.pdf`);

        // Append the <a> element to the body and click it to trigger the download
        document.body.appendChild(tempLink);
        tempLink.click();

        // Clean up the temporary elements and URL
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(url);
        setIsSuccess(true);
      })
      .catch((e) => {
        switch (e.request["status"]) {
          case 410:
            setError(t("invoices.expiration_error"));
            break;
          case 403:
            setError(t("invoices.user_error"));
            break;
          default:
            setError(t("invoices.download_error"));
        }
      });

    setIsLoading(false);
  }
  return (
    <FullScreenCenteredLayout>
      <Form onSubmit={onSubmit}>
        {isLoading && <Spinner />}
        {!isLoading &&
          (isSuccess ? (
            <>{t("invoices.success_token")}</>
          ) : (
            <Stack spacing={2}>
              <Typography variant="body">{t("invoices.summary")}</Typography>
              <LegacyTextField
                label={t("login.username")}
                value={username}
                setState={setUser}
              />
              <Button type="submit">{t("common.download")}</Button>
              {error && <Alert severity="error">{error}</Alert>}
            </Stack>
          ))}
      </Form>
    </FullScreenCenteredLayout>
  );
};
