import { Box, Typography } from "@mui/material";
import SearchIcon from "components/icons/search";
import { useTranslation } from "react-i18next";

export const Header = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
      <SearchIcon />
      <Typography variant="h4">{t("faqs.highlights.title")}</Typography>
    </Box>
  );
};
