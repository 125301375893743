export const Forest = ({ width = "353", height = "89" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 546 137"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M401.92 40.2489C398.998 40.4684 396.645 42.6478 394.682 44.8101C388.055 52.1042 382.837 60.6602 379.397 69.876C376.263 78.2703 374.627 87.6544 377.462 96.1551C380.239 104.486 387.24 111.54 396.85 111.335L406.127 111.094C414.444 112.297 423.212 106.078 425.856 98.1426C429.613 86.8709 421.526 59.7532 410.865 45.7408C408.657 42.8384 405.569 39.9754 401.92 40.2489Z"
      fill="#FFBC3F"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M407.328 107.807C408.006 106.198 409.032 104.763 410.082 103.367C411.783 101.104 413.569 98.9039 415.432 96.7724C415.765 96.3916 416.117 95.9752 416.157 95.4722C416.221 94.6632 415.395 93.9961 414.582 93.958C413.769 93.9199 413.006 94.3401 412.345 94.8142C410.308 96.2747 408.79 98.3274 407.024 100.106C406.842 100.288 406.608 100.484 406.357 100.422C406.116 100.363 405.997 100.098 405.92 99.864C405.025 97.1677 405.1 94.2443 405.465 91.4272C406.149 86.145 407.828 80.9928 410.388 76.3174C411.193 74.8464 412.115 73.1943 411.623 71.5933C411.526 71.2794 411.35 70.9564 411.043 70.8382C410.7 70.7055 410.318 70.8684 409.997 71.0457C407.023 72.686 405.084 75.7421 403.858 78.902C402.632 82.0619 401.996 85.42 400.886 88.6219C399.296 84.3772 397.487 79.8515 393.693 77.3575C393.477 77.2157 393.242 77.0765 392.983 77.0673C392.395 77.0463 391.987 77.7266 392.055 78.3084C392.124 78.8902 392.506 79.38 392.859 79.8489C395.681 83.605 397.409 88.1688 397.779 92.8482C397.867 93.9619 397.858 95.1597 397.239 96.0895C395.174 94.4465 393.108 92.8036 391.043 91.1606C390.482 90.7141 389.575 90.299 389.148 90.8743C388.964 91.1225 388.973 91.4626 389.027 91.7673C389.321 93.4445 390.62 94.7315 391.71 96.0435C397.21 102.669 400.886 116.736 400.886 127.454C400.886 138.172 406.357 135.216 405.92 132.998C407.114 127.065 405.303 118.639 406.233 112.134C406.444 110.657 406.754 109.183 407.332 107.807H407.328Z"
      fill="black"
    />
    <path
      d="M410.597 133.469C406.769 132.582 406.057 127.679 406.18 125.338C404.708 124.23 401.248 123.61 400.66 128.932C400.071 134.255 387.778 135.215 386.306 134.578C384.834 133.941 383.361 136.057 383.361 136.057H421.638C421.638 136.057 422.374 135.215 421.638 134.578C420.902 133.941 414.424 134.356 410.597 133.469Z"
      fill="black"
    />
    <path
      d="M399.254 133.471C398.915 132.932 397.436 130.1 397.436 130.1"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M398.517 134.95C398.179 134.41 396.699 131.579 396.699 131.579"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M414.652 119.323C415.661 119.796 418.222 122.539 417.074 124.653C415.926 126.767 414.446 128.253 414.652 127.979C414.858 127.706 412.9 125.34 413.71 122.502C414.52 119.663 414.652 119.323 414.652 119.323Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M413.173 134.403C413.173 134.403 415.69 127.232 415.44 121.755"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M398.442 57.861C399.669 59.05 401.304 60.0408 401.918 59.4463C402.408 58.9707 403.349 58.1912 403.758 57.861"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M390.174 61.9746C391.4 63.1636 393.036 64.1544 393.649 63.5599C394.14 63.0843 395.08 62.3049 395.489 61.9746"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M406.12 63.7377C407.347 64.9267 408.982 65.9175 409.596 65.323C410.086 64.8474 411.027 64.0679 411.435 63.7377"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M396.671 69.0267C397.898 70.2157 399.533 71.2065 400.146 70.6121C400.637 70.1365 401.577 69.357 401.986 69.0267"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M384.858 71.3774C386.085 72.5664 387.72 73.5573 388.334 72.9628C388.824 72.4872 389.765 71.7077 390.174 71.3774"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M413.798 76.0787C415.024 77.2677 416.66 78.2585 417.273 77.6641C417.764 77.1885 418.704 76.409 419.113 76.0787"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M401.396 49.0458C402.622 50.2348 404.258 51.2256 404.871 50.6311C405.362 50.1555 406.302 49.3761 406.711 49.0458"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M381.906 80.1925C383.133 81.3815 384.768 82.3723 385.382 81.7778C385.872 81.3022 386.813 80.5228 387.222 80.1925"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M410.845 83.7185C412.071 84.9075 413.707 85.8983 414.32 85.3038C414.811 84.8282 415.751 84.0488 416.16 83.7185"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M416.16 90.183C417.387 91.372 419.022 92.3628 419.636 91.7683C420.126 91.2927 421.067 90.5133 421.475 90.183"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M387.222 86.0692C388.448 87.2582 390.084 88.249 390.697 87.6545C391.188 87.1789 392.128 86.3995 392.537 86.0692"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M383.087 97.235C384.314 98.424 385.949 99.4148 386.562 98.8203C387.053 98.3447 387.993 97.5653 388.402 97.235"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M108.168 133.201C107.801 132.614 106.197 129.533 106.197 129.533"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M128.191 130.112V133.868L130.601 131.994"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M97.7326 135.381C98.6101 135.115 146.644 135.381 146.644 135.381L144.602 133.209C144.602 133.209 122.336 135.71 119.998 127.393C117.661 119.076 116.568 127.393 116.568 127.393C116.568 127.393 116.783 129.678 112.914 130.924C109.045 132.171 98.8255 134.013 98.6022 134.086C98.3788 134.158 97.7246 135.381 97.7246 135.381H97.7326Z"
      fill="black"
    />
    <path
      d="M120.478 24.0682C120.539 24.5831 120.596 25.1674 120.65 25.8189C120.901 28.8758 121.055 33.265 121.135 38.5598C121.293 49.1419 121.149 63.261 120.885 77.3912C120.622 91.5189 120.24 105.648 119.924 116.245C119.766 121.544 119.625 125.959 119.523 129.05C119.472 130.595 119.43 131.809 119.402 132.637C119.396 132.82 119.39 132.984 119.385 133.129C119.068 133.248 118.79 133.408 118.563 133.551C118.374 133.67 118.187 133.8 118.026 133.912C117.995 133.934 117.966 133.954 117.938 133.974C117.746 134.107 117.595 134.208 117.461 134.282C117.387 134.323 117.333 134.348 117.296 134.362C117.23 134.284 117.09 134.081 116.919 133.603C116.923 133.614 116.922 133.612 116.919 133.59C116.912 133.55 116.894 133.441 116.873 133.232C116.845 132.953 116.818 132.571 116.794 132.087C116.746 131.122 116.71 129.789 116.684 128.137C116.634 124.834 116.63 120.287 116.669 114.915C116.748 104.171 117.001 90.1471 117.41 76.2236C117.819 62.298 118.382 48.4839 119.08 38.1564C119.429 32.9889 119.81 28.7144 120.22 25.7406C120.306 25.1193 120.392 24.5613 120.478 24.0682ZM117.231 134.382C117.231 134.381 117.234 134.381 117.239 134.381C117.233 134.382 117.231 134.382 117.231 134.382Z"
      fill="black"
      stroke="black"
      strokeWidth="2"
    />
    <path
      d="M134.534 118.61C135.627 119.124 138.403 122.109 137.158 124.409C135.914 126.71 134.31 128.326 134.534 128.029C134.757 127.731 132.635 125.157 133.513 122.068C134.39 118.98 134.534 118.61 134.534 118.61Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M132.931 135.019C132.931 135.019 135.659 127.216 135.388 121.256"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M142.704 125.092C142.704 125.092 142.999 129.878 140.366 130.763C137.733 131.648 137.127 131.527 137.127 131.527C137.127 131.527 136.497 127.376 139.058 126.049C141.619 124.722 142.712 125.092 142.712 125.092H142.704Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M134.526 134.801C134.822 134.286 137.957 130.159 141.244 127.368"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M97.0312 127.1C97.47 127.317 99.6559 131.443 102.289 131.443C104.921 131.443 102.656 127.027 100.685 126.73C98.7145 126.432 97.0312 127.1 97.0312 127.1Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M100.254 128.145C100.254 128.145 104.107 130.438 104.107 134.09"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M118.387 63.2611C118.387 63.2611 133.88 80.8848 140.23 86.8934C146.908 93.2077 158.14 97.1089 147.721 100.914C137.302 104.718 117.095 104.115 110.505 103.914C103.916 103.713 86.2611 100.109 84.7693 97.1089C83.2774 94.1086 96.6561 90.5292 104.474 80.8848C112.292 71.2404 118.379 63.2611 118.379 63.2611"
      fill="#65AA4B"
    />
    <path
      d="M118.387 63.2611C118.387 63.2611 133.88 80.8848 140.23 86.8934C146.908 93.2077 158.14 97.1089 147.721 100.914C137.302 104.718 117.095 104.115 110.505 103.914C103.916 103.713 86.2611 100.109 84.7693 97.1089C83.2774 94.1086 96.6561 90.5292 104.474 80.8848C112.292 71.2404 118.379 63.2611 118.379 63.2611"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M124.227 79.9276C126.078 79.8713 129.189 79.3887 132.3 78.6969C125.671 71.5541 118.387 63.2611 118.387 63.2611C118.387 63.2611 112.931 70.4119 105.719 79.3565C112.715 80.2976 120.94 80.0322 124.227 79.9276Z"
      fill="black"
    />
    <path
      d="M118.818 46.1442C118.818 46.1442 106.198 60.3493 100.701 64.4516C95.6033 68.2563 91.5187 71.0233 98.6747 73.8225C105.831 76.6217 119.704 76.1793 124.227 76.0345C128.751 75.8897 140.869 73.2353 141.898 71.0313C142.927 68.8274 129.309 57.9523 123.94 50.8658C118.571 43.7794 118.826 46.1522 118.826 46.1522"
      fill="#65AA4B"
    />
    <path
      d="M118.818 46.1442C118.818 46.1442 106.198 60.3493 100.701 64.4516C95.6033 68.2563 91.5187 71.0233 98.6747 73.8225C105.831 76.6217 119.704 76.1793 124.227 76.0345C128.751 75.8897 140.869 73.2353 141.898 71.0313C142.927 68.8274 129.309 57.9523 123.94 50.8658C118.571 43.7794 118.826 46.1522 118.826 46.1522"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M126.349 59.7782C125.998 58.3786 125.408 57.164 124.929 56.3275C125.958 57.6869 127.921 59.5771 130.29 58.2338C130.378 58.1856 130.466 58.1373 130.553 58.0971C128.017 55.5714 125.591 53.0376 123.94 50.8658C118.571 43.7794 118.826 46.1522 118.826 46.1522C118.826 46.1522 113.657 51.9678 108.495 57.2284C111.567 56.9388 114.048 55.499 114.303 55.3622C114.742 55.1451 110.362 59.7782 113.649 59.7782C116.936 59.7782 127.011 62.4246 126.349 59.7782Z"
      fill="black"
    />
    <path
      d="M133.154 46.9968C138.946 51.0106 133.793 53.7213 130.29 55.7081C126.788 57.6949 124.156 52.6193 124.156 52.6193C124.156 52.6193 125.687 54.6061 126.349 57.2605C127.012 59.9149 116.936 57.2605 113.649 57.2605C110.362 57.2605 114.742 52.6193 114.303 52.8445C113.864 53.0698 109.181 55.9494 103.924 51.7586C100.996 49.4179 105.942 46.6268 111.511 42.436C115.931 39.1059 118.683 35.0841 119.943 35.0841C122.297 35.0841 127.363 42.991 133.154 47.0048V46.9968Z"
      fill="#65AA4B"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M100.169 92.3918C100.227 92.6224 100.808 95.6657 101.534 94.5113C101.923 93.893 101.947 94.6574 102.433 94.9884C103.465 95.6918 103.91 94.885 104.816 94.5959C105.269 94.4514 105.702 94.575 106.158 94.6261C107.377 94.7627 108.447 94.5839 109.655 94.5657"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M125.609 93.5874C126.304 93.9834 128.251 95.4485 128.843 94.149C128.924 93.973 128.879 93.6349 129.119 93.829C129.541 94.1708 129.884 94.482 130.466 94.5113C130.919 94.5341 131.222 94.4146 131.574 94.143C131.77 93.9921 132.098 93.3497 132.389 93.3338C132.91 93.3053 133.497 93.7739 134.048 93.7988C134.326 93.8113 134.613 93.8006 134.88 93.7203C136.353 93.2777 134.953 93.4196 136.282 92.7179"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M106.421 65.7623C106.599 66.1368 107.128 67.8586 107.876 67.7369C108.298 67.6683 108.333 67.0624 108.445 66.7647C108.536 66.5241 108.722 67.1975 108.864 67.4108C109.182 67.8853 109.654 68.3934 110.29 68.3226C110.821 68.2635 111.076 67.7725 111.434 67.4591C111.871 67.0763 112.223 67.2936 112.721 67.441C114.422 67.9435 116.023 67.7982 117.74 67.5014"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M127.657 66.5232C128.233 66.4766 128.807 66.3924 129.382 66.3542C129.961 66.3157 130.185 66.1054 130.676 65.8711"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M114.938 45.0022C114.981 45.5177 114.933 46.3956 115.548 46.6386C116.163 46.8816 116.73 46.2713 117.141 45.9321C117.549 45.5961 117.756 45.3683 118.273 45.6181C119.005 45.9715 119.62 46.501 120.477 46.5118C120.868 46.5167 120.991 46.3101 121.322 46.234C121.548 46.1821 121.886 46.4497 122.07 46.5178C123.367 46.9966 124.537 46.6559 125.718 46.0891"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M109.439 84.8921C110.443 85.3983 111.645 86.2091 112.841 86.0635C113.429 85.992 113.421 85.4999 113.871 85.3752C114.321 85.2504 115.341 85.7095 115.74 85.7677C117.002 85.9516 118.109 85.7077 119.357 85.6529"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.0094 97.6189C66.6237 96.9264 71.1425 95.7011 75.4564 93.9699C79.1561 92.4916 82.8557 90.4807 84.9444 87.1513C87.197 83.5422 87.156 79.0542 87.0468 74.8325C87.0059 73.341 86.9512 71.7961 86.296 70.4378C85.668 69.1326 84.5212 68.1338 83.3199 67.2815C80.4803 65.2572 77.1629 63.8855 73.6954 63.3129C78.651 61.6082 84.0161 59.5573 86.5417 55.056C87.6475 53.085 88.0843 50.821 88.4939 48.6103C88.8625 46.6659 89.2174 44.6816 88.9035 42.7239C88.6168 40.9927 87.8113 39.3812 86.8147 37.9163C82.7192 31.8435 75.238 28.1146 67.7978 28.4209C69.163 26.6363 71.593 25.8639 72.9445 24.066C74.4735 22.0018 74.1868 19.1252 73.2722 16.7414C70.0913 8.39129 60.7944 3.70351 51.7843 2.23858C47.3747 1.51944 42.7877 1.38626 38.4738 2.5582C34.1598 3.73015 30.1325 6.2738 27.8527 10.0294C27.1701 11.148 26.6377 12.4132 26.7332 13.705C26.8151 15.0634 27.566 16.2886 28.3168 17.4472C32.4943 23.8663 37.4772 29.8059 43.1154 35.0663C39.484 33.9077 35.484 34.5336 31.8663 35.7589C25.2861 37.9829 18.9108 42.9903 18.3237 49.7556C18.2828 50.235 18.2691 50.7411 18.4603 51.1806C18.7333 51.8065 19.3476 52.206 19.9346 52.5656C24.8493 55.5088 30.3373 57.5464 36.0164 58.5452C28.5353 60.7559 24.0029 67.9607 20.1667 74.6061C18.8152 76.95 17.4091 79.4937 17.6958 82.1705C17.9824 84.8074 19.9073 87.0447 22.2145 88.4431C24.5216 89.8414 27.1974 90.494 29.8322 91.1465C36.8082 92.8645 43.7707 94.5958 50.7467 96.3138L61.9958 97.6588L62.0094 97.6189Z"
      fill="#AC6540"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.6279 40.9926C42.0784 41.0991 46.0238 47.3717 52.4538 45.5072"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.2764 76.5105C41.0682 77.1764 48.1671 85.966 52.563 80.7988C56.9588 75.6316 55.6483 61.0089 55.6483 61.0089C55.6483 61.0089 63.1568 63.7523 66.3103 54.7363"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.2888 12.7434C55.5429 11.261 56.5655 42.7633 57.5199 56.5025C58.4743 70.2418 62.7966 124.007 61.1195 129.593C59.4424 135.179 54.643 137.508 52.9659 130.996C51.2888 124.484 52.2432 57.2173 51.2888 46.5091C50.3344 35.801 47.9346 13.9215 51.2888 12.7567V12.7434Z"
      fill="black"
    />
    <path
      d="M67.4717 131.895C61.8275 130.619 60.7783 123.568 60.9592 120.202C58.7885 118.607 53.6872 117.717 52.8189 125.37C51.9505 133.024 33.824 134.406 31.6532 133.489C29.4824 132.573 27.3115 135.615 27.3115 135.615H83.7533C83.7533 135.615 84.8387 134.406 83.7533 133.489C82.6679 132.573 73.1159 133.17 67.4717 131.895Z"
      fill="black"
    />
    <path
      d="M23.5127 125.96C24.1097 126.247 27.0837 131.7 30.6656 131.7C34.2475 131.7 31.1649 125.864 28.4839 125.471C25.8029 125.078 23.5127 125.96 23.5127 125.96Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.8975 127.342C27.8975 127.342 33.14 130.371 33.14 135.197"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.7462 129.622C44.2035 131.217 43.6717 134.705 43.0404 133.102C42.7921 132.471 42.0615 131.229 41.9648 130.756"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M65.5107 133.355C65.6334 131.589 65.8917 129.91 66.2441 128.184"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M211.122 117.034C212.58 118.138 215.001 126.635 215.001 126.635C215.001 126.635 213.504 125.302 211.698 125.645C210.725 125.832 209.999 126.745 208.569 127.358C208.273 127.143 207.977 126.929 207.684 126.714C207.586 126.641 207.481 126.562 207.439 126.448C207.335 126.171 207.638 125.925 207.886 125.759C208.13 125.589 208.378 125.247 208.172 125.032C208.099 124.956 207.987 124.928 207.879 124.904C207.307 124.773 206.731 124.641 206.156 124.509C206.034 124.482 205.891 124.44 205.835 124.326C205.797 124.253 205.804 124.167 205.825 124.087C206.02 123.281 207.234 122.935 207.223 122.108C207.216 121.509 206.571 121.156 206.037 120.882C205.929 120.827 205.818 120.761 205.776 120.65C205.713 120.491 205.825 120.318 205.947 120.197C206.372 119.782 206.979 119.609 207.467 119.266C207.956 118.923 208.318 118.235 207.98 117.747C207.809 117.501 207.471 117.304 207.527 117.009C207.562 116.816 207.76 116.698 207.942 116.622C208.43 116.418 208.96 116.303 209.49 116.29L209.518 116.203C210.174 116.414 210.683 116.701 211.122 117.034Z"
      fill="black"
    />
    <path
      d="M207.886 125.759C207.638 125.925 207.335 126.171 207.439 126.448C207.481 126.562 207.586 126.641 207.684 126.714C207.977 126.929 208.273 127.143 208.569 127.358C208.468 127.399 208.364 127.444 208.256 127.482C207.049 127.929 205.368 128.14 202.752 127.624C196.572 126.406 191.117 119.816 191.117 119.816C191.117 119.816 190.734 121.187 190.887 122.824C190.936 123.353 191.124 124.167 191.344 125.091C190.884 125.427 189.621 126.659 188.683 126.794C188.62 126.721 188.547 126.655 188.47 126.596C187.679 126.012 187.047 125.302 186.175 124.755C186.095 123.976 186.008 123.433 185.879 123.433C185.544 123.433 181.701 122.589 181.659 123.893C179.465 123.911 178.37 124.046 178.37 124.046C178.37 124.046 178.433 124.901 178.517 126.15C178.405 126.16 175.925 126.368 175.915 126.292C175.81 125.427 175.66 124.814 175.454 124.731L175.416 111.776C176.874 112.309 181.788 109.26 186.549 111.358C191.309 113.451 196.07 116.957 201.755 116.158C205.724 115.597 208.004 115.708 209.518 116.203L209.49 116.289C208.96 116.303 208.43 116.418 207.942 116.622C207.76 116.698 207.562 116.816 207.527 117.009C207.471 117.304 207.809 117.501 207.98 117.747C208.318 118.235 207.956 118.923 207.467 119.266C206.979 119.609 206.372 119.782 205.947 120.197C205.825 120.318 205.713 120.491 205.776 120.65C205.818 120.761 205.929 120.827 206.037 120.882C206.571 121.156 207.216 121.509 207.223 122.107C207.234 122.935 206.02 123.281 205.825 124.087C205.804 124.167 205.797 124.253 205.835 124.326C205.891 124.44 206.034 124.482 206.156 124.509C206.731 124.641 207.307 124.772 207.879 124.904C207.987 124.928 208.099 124.956 208.172 125.032C208.378 125.247 208.13 125.589 207.886 125.759Z"
      fill="black"
    />
    <path
      d="M191.344 125.091C191.801 127.022 192.404 129.434 192.195 130.75C191.884 132.695 191.309 135.284 190.273 135.436C189.237 135.588 187.085 135.931 187.469 135.055C187.856 134.179 189.237 133.799 189.391 132.885C189.537 132.016 189.924 128.137 188.683 126.794C189.621 126.659 190.883 125.427 191.344 125.091Z"
      fill="black"
    />
    <path
      d="M181.659 123.893C181.701 122.589 185.544 123.433 185.879 123.433C186.008 123.433 186.095 123.976 186.176 124.755C185.398 124.267 184.425 123.911 182.978 123.893C182.5 123.886 182.06 123.886 181.659 123.893Z"
      fill="black"
    />
    <path
      d="M181.659 123.893C182.06 123.886 182.499 123.886 182.977 123.893C184.425 123.911 185.398 124.267 186.175 124.755C186.311 126.081 186.43 128.088 186.685 129.206C187.086 130.978 185.244 135.893 185.014 135.824C184.784 135.751 182.768 135.779 182.077 135.824C181.383 135.865 181.443 134.636 182.824 133.951C184.208 133.266 184.323 131.951 183.978 129.722C183.633 127.493 181.847 124.291 181.673 124.063C181.662 124.004 181.655 123.945 181.659 123.893Z"
      fill="black"
    />
    <path
      d="M179.023 106.021C179.406 106.249 177.642 106.439 177.91 106.516C178.179 106.592 180.215 108.08 180.024 108.156H180.017L174.3 108.087C174.3 108.087 171.939 105.889 170.844 105.699C169.753 105.508 167.161 107.959 167.161 107.959C167.161 107.959 161.107 108.589 160.029 108.648H159.945L161.898 106.82C161.898 106.82 160.671 106.592 160.786 106.592C160.838 106.592 161.442 105.107 162.697 103.491C162.91 103.913 163.109 104.207 163.269 104.304C163.482 104.432 163.883 104.276 164.368 103.965C165.466 103.262 167.011 101.757 167.841 100.916C168.201 100.552 168.427 100.31 168.427 100.31C168.427 100.31 168.103 100.123 167.594 99.8323C168.403 99.5692 169.296 99.4204 170.269 99.4308C171.27 99.4412 172.162 99.5969 172.954 99.8565C172.316 100.296 171.887 100.604 171.887 100.604C171.887 100.604 172.169 100.867 172.595 101.258C173.544 102.134 175.211 103.667 176.062 104.415C176.334 104.657 176.526 104.82 176.574 104.847C176.658 104.896 176.951 104.134 177.272 103.065C178.37 104.532 178.848 105.917 179.023 106.021ZM174.45 105.007C174.45 104.743 174.227 104.529 173.952 104.529C173.676 104.529 173.453 104.743 173.453 105.007C173.453 105.27 173.676 105.481 173.952 105.481C174.227 105.481 174.45 105.27 174.45 105.007ZM168.194 105.007C168.194 104.743 167.97 104.529 167.695 104.529C167.419 104.529 167.196 104.743 167.196 105.007C167.196 105.27 167.419 105.481 167.695 105.481C167.97 105.481 168.194 105.27 168.194 105.007Z"
      fill="black"
    />
    <path
      d="M178.516 126.15C178.753 129.497 179.161 135.664 179.022 135.664C178.83 135.664 175.838 136.121 175.492 135.474C175.144 134.827 176.183 133.761 176.183 133.19C176.183 132.774 176.204 128.621 175.914 126.292C175.925 126.368 178.405 126.16 178.516 126.15Z"
      fill="black"
    />
    <path
      d="M178.022 96.8593C178.914 96.6724 178.008 100.653 177.272 103.065C177.073 102.791 176.846 102.518 176.599 102.248C176.986 100.459 177.15 98.877 176.578 98.9947C176.093 99.0985 175.106 99.6523 174.063 100.306C173.718 100.137 173.348 99.9811 172.954 99.8565C174.457 98.8182 177.115 97.0496 178.022 96.8593Z"
      fill="black"
    />
    <path
      d="M177.272 103.065C176.951 104.134 176.658 104.896 176.574 104.847C176.525 104.82 176.334 104.657 176.062 104.415C176.253 103.726 176.445 102.971 176.599 102.248C176.846 102.518 177.073 102.791 177.272 103.065Z"
      fill="black"
    />
    <path
      d="M176.577 98.9947C177.149 98.877 176.985 100.459 176.598 102.248C175.935 101.521 175.102 100.826 174.062 100.306C175.105 99.6523 176.092 99.0985 176.577 98.9947Z"
      fill="black"
    />
    <path
      d="M176.061 104.415C175.21 103.667 173.543 102.134 172.595 101.258C173.066 100.943 173.568 100.614 174.063 100.306C175.102 100.826 175.936 101.521 176.599 102.248C176.445 102.971 176.253 103.726 176.061 104.415Z"
      fill="black"
    />
    <path
      d="M175.455 124.731C172.745 115.68 175.416 111.776 175.416 111.776L175.455 124.731Z"
      fill="black"
    />
    <path
      d="M173.951 104.529C174.226 104.529 174.45 104.744 174.45 105.007C174.45 105.27 174.226 105.481 173.951 105.481C173.675 105.481 173.452 105.27 173.452 105.007C173.452 104.744 173.675 104.529 173.951 104.529Z"
      fill="black"
    />
    <path
      d="M172.954 99.8566C173.348 99.9812 173.718 100.137 174.063 100.307C173.568 100.615 173.066 100.943 172.595 101.258C172.169 100.867 171.887 100.604 171.887 100.604C171.887 100.604 172.316 100.296 172.954 99.8566Z"
      fill="black"
    />
    <path
      d="M169.906 107.073C169.819 106.574 171.863 106.661 171.786 106.972C171.73 107.201 171.559 108.07 171.224 108.087C170.89 108.108 169.965 107.405 169.906 107.073Z"
      fill="black"
    />
    <path
      d="M171.998 125.998C171.51 128.735 171.095 134.671 170.153 135.093C168.964 135.626 166.313 135.779 165.929 135.588C164.995 135.124 166.428 134.789 167.582 134.37C168.733 133.951 168.158 128.922 168.426 126.254C168.447 126.057 168.451 125.835 168.44 125.593C168.779 125.593 171.998 125.998 171.998 125.998Z"
      fill="black"
    />
    <path
      d="M171.224 108.087C171.558 108.07 171.729 107.201 171.785 106.972C171.862 106.661 169.818 106.574 169.905 107.073C169.965 107.405 170.889 108.108 171.224 108.087ZM180.016 108.156L160.028 108.648C161.106 108.589 167.161 107.959 167.161 107.959C167.161 107.959 169.752 105.508 170.843 105.699C171.939 105.889 174.3 108.087 174.3 108.087L180.016 108.156Z"
      fill="black"
    />
    <path
      d="M168.427 100.31C168.427 100.31 168.2 100.552 167.841 100.916C167.478 100.711 167.046 100.473 166.596 100.23C166.913 100.078 167.244 99.9465 167.59 99.8323H167.593C168.102 100.123 168.427 100.31 168.427 100.31Z"
      fill="black"
    />
    <path
      d="M166.596 100.23C167.046 100.473 167.479 100.711 167.841 100.916C167.011 101.757 165.466 103.262 164.368 103.965C164.155 103.536 163.921 102.954 163.712 102.328C164.5 101.521 165.456 100.767 166.596 100.23Z"
      fill="black"
    />
    <path
      d="M167.694 104.529C167.97 104.529 168.193 104.744 168.193 105.007C168.193 105.27 167.97 105.481 167.694 105.481C167.419 105.481 167.195 105.27 167.195 105.007C167.195 104.744 167.419 104.529 167.694 104.529Z"
      fill="black"
    />
    <path
      d="M167.59 99.8323C167.244 99.9465 166.913 100.078 166.596 100.23C165.284 99.5277 163.809 98.7974 163.331 98.7974C162.787 98.7974 163.167 100.694 163.711 102.328C163.335 102.708 162.996 103.103 162.696 103.491C161.702 101.532 160.391 96.8593 161.354 96.8593C162.25 96.8593 165.919 98.8805 167.59 99.8323Z"
      fill="black"
    />
    <path
      d="M163.332 98.7975C163.81 98.7975 165.285 99.5278 166.596 100.23C165.456 100.767 164.5 101.521 163.712 102.328C163.168 100.694 162.788 98.7975 163.332 98.7975Z"
      fill="black"
    />
    <path
      d="M163.711 102.328C163.92 102.954 164.154 103.536 164.367 103.965C163.882 104.276 163.481 104.432 163.268 104.304C163.108 104.207 162.909 103.913 162.696 103.491C162.996 103.103 163.335 102.708 163.711 102.328Z"
      fill="black"
    />
    <path
      d="M165.277 111.087C165.086 111.011 159.941 108.651 159.941 108.651H160.029L180.016 108.156H180.023C179.831 108.232 173.955 111.243 175.416 111.776C175.416 111.776 172.745 115.68 175.454 124.731C174.684 124.426 173.149 124.122 172.42 124.655C172.267 124.765 172.131 125.267 171.998 125.998C171.998 125.998 168.779 125.593 168.441 125.593C168.333 122.959 166.547 118.03 165.759 115.203C164.894 112.119 165.469 111.164 165.277 111.087Z"
      fill="black"
    />
    <path
      d="M433.517 110.321C435.548 109.184 436.446 112.047 436.377 112.29L434.58 114.185C434.336 114.2 433.53 114.082 432.259 113.489C430.67 112.747 431.486 111.457 433.517 110.321Z"
      fill="black"
    />
    <path
      d="M456.552 101.807C458.458 104.844 458.371 107.758 458.089 108.836L456.86 108.555C456.347 107.992 455.123 106.516 454.323 105.111C453.324 103.354 452.325 98.9961 452.94 98.223C453.555 97.4499 454.17 98.0121 456.552 101.807Z"
      fill="black"
    />
    <path
      d="M459.378 117.637C457.484 116.76 456.443 115.688 456.254 115.786L453.887 118.124C454.676 118.417 456.727 119.079 458.62 119.391C460.987 119.781 462.691 122.996 463.259 123.288C463.827 123.581 464.111 123.191 463.827 122.119C463.543 121.047 461.271 118.514 459.378 117.637Z"
      fill="black"
    />
    <path
      d="M433.248 130.893C432.979 131.892 432.979 132.319 433.248 132.538C433.915 132.538 434.678 132.538 435.345 131.759C436.012 130.979 436.965 125.523 437.632 124.57C438.292 123.629 439.79 123.364 443.139 122.772L443.255 122.752C446 122.267 447.195 117.642 447.449 115.39L435.726 120.846C435.567 121.337 435.211 122.96 435.059 125.523C434.868 128.728 433.496 129.975 433.248 130.893Z"
      fill="black"
    />
    <path
      d="M427.345 130.008C426.612 131.153 427.263 131 427.345 132.068C428.648 132.526 429.543 130.924 430.032 130.008C430.521 129.093 429.869 125.279 434.267 124.363C437.785 123.631 439.533 121.261 439.968 120.167C440.863 119.735 442.72 118.84 442.981 118.718C443.307 118.565 447.134 120.244 452.672 118.413C457.102 116.948 456.718 115.06 456.881 114.221C457.424 114.221 459.131 116.331 460.675 115.682C462.761 114.805 462.815 114.84 463.141 112.857C463.466 110.873 460.415 108.292 457.483 107.834C454.551 107.376 449.252 110.267 444.365 109.504C439.479 108.741 436.466 111.539 434.593 113.675C432.72 115.811 433.453 120.701 432.394 122.227C431.335 123.753 431.009 122.761 430.521 123.143C430.032 123.524 428.078 128.864 427.345 130.008Z"
      fill="black"
    />
    <path
      d="M452.867 122.308C450.311 121.918 448.134 118.021 447.944 117.826L454.477 117.533C454.382 117.696 454.249 118.352 454.477 119.677C454.761 121.333 456.938 121.918 457.885 123.087C458.832 124.256 459.305 125.621 458.926 126.595C458.548 127.569 458.074 127.277 457.506 125.913C456.938 124.549 455.423 122.697 452.867 122.308Z"
      fill="black"
    />
    <ellipse
      cx="458.419"
      cy="110.69"
      rx="0.414777"
      ry="0.426882"
      fill="black"
    />
    <path
      d="M462.727 112.756C462.596 112.388 461.55 112.388 461.158 112.511C460.845 112.903 461.55 113.574 461.943 113.86C462.248 113.615 462.831 113.05 462.727 112.756Z"
      fill="black"
    />
    <path
      d="M448.253 99.7032C449.669 99.2204 454.745 105.493 457.106 108.689L455.885 109.226C454.948 108.801 452.551 107.683 450.451 106.61C447.825 105.269 446.482 100.307 448.253 99.7032Z"
      fill="black"
    />
    <path
      d="M456.38 109.022L455.55 109.306L454.168 108.595C452.693 108.073 449.605 106.66 449.052 105.18C448.499 103.7 448.545 102.097 448.637 101.48C448.637 101.053 449.08 100.627 450.85 102.334C452.619 104.042 455.274 107.504 456.38 109.022Z"
      fill="black"
    />
    <path
      d="M360.023 134.086C359.004 134.407 357.927 134.541 356.861 134.479C355.728 134.412 354.506 134.056 353.873 133.107C353.518 132.574 353.394 131.916 353.387 131.276C353.383 131.022 353.397 130.764 353.487 130.528C353.602 130.224 353.832 129.977 354.086 129.774C355.072 128.984 357.529 129.116 357.668 128.864C357.806 128.612 352.604 127.88 352.604 131.816C352.604 136.88 361.607 135.25 361.607 135.25C361.607 135.25 361.442 133.908 361.333 133.908C361.225 133.908 360.025 134.088 360.025 134.088L360.023 134.086Z"
      fill="black"
    />
    <path
      d="M371.904 122.635C372.232 122.891 372.106 123.443 371.783 123.57C371.664 123.616 371.494 123.593 371.364 123.584C371.126 123.568 370.998 123.399 370.824 123.231C370.808 123.215 370.791 123.198 370.778 123.18C370.922 123.157 371.005 123.147 371.005 123.147C371.005 123.147 371.334 122.824 371.264 122.499C371.371 122.446 371.51 122.474 371.634 122.513C371.727 122.542 371.824 122.575 371.904 122.637V122.635Z"
      fill="black"
    />
    <path
      d="M371.083 134.501C371.297 134.576 371.497 134.732 371.567 134.949C371.176 134.959 369.992 135.19 369.342 135.19C368.975 135.1 368.607 135.019 368.24 134.938C368.168 134.922 368.098 134.908 368.026 134.892L367.89 134.345C367.883 134.308 367.877 134.269 367.872 134.229L370.412 134.404C370.638 134.414 370.869 134.425 371.083 134.501Z"
      fill="black"
    />
    <path
      d="M371.264 122.497C371.334 122.822 371.004 123.145 371.004 123.145C371.004 123.145 370.922 123.157 370.778 123.178C370.766 123.163 370.759 123.147 370.755 123.129C370.747 123.081 370.778 123.039 370.806 123.006C370.911 122.878 371.017 122.751 371.122 122.624C371.162 122.575 371.204 122.526 371.261 122.497C371.261 122.497 371.261 122.497 371.262 122.497H371.264Z"
      fill="black"
    />
    <path
      d="M368.027 123.778C368.023 124.367 367.981 125.265 367.929 126.301C367.915 126.558 367.902 126.827 367.888 127.1C367.766 129.621 367.636 132.666 367.872 134.227H367.862L367.892 134.344C367.929 134.561 367.974 134.746 368.029 134.89C367.336 134.74 366.636 134.601 365.927 134.589C365.424 134.58 364.554 134.501 364.33 134.802C364.06 135.164 363.437 135.282 363.36 135.294C363.36 135.294 363.356 135.294 363.355 135.294C363.07 135.391 362.769 135.441 362.457 135.432C360.924 135.391 360.263 134.084 360.263 134.084C359.191 132.426 359.019 130.217 359.815 128.411C360.498 126.867 361.802 125.671 362.5 124.136C362.802 123.473 362.985 122.762 363.255 122.085C363.525 121.407 363.898 120.744 364.488 120.319C364.682 120.178 364.891 120.068 365.112 119.978C366.117 120.836 367.492 119.973 367.492 119.973C367.609 119.826 367.692 119.657 367.744 119.477C367.853 119.486 367.964 119.5 368.071 119.525C368.597 119.647 369.049 119.983 369.433 120.366C369.714 120.647 370.858 121.656 371.233 122.39C371.25 122.423 371.263 122.459 371.27 122.494C371.27 122.494 371.27 122.494 371.268 122.494C371.21 122.524 371.17 122.571 371.129 122.621C371.024 122.748 370.919 122.875 370.814 123.002C370.784 123.037 370.754 123.078 370.763 123.125C370.766 123.143 370.775 123.159 370.786 123.175C370.535 123.214 370.102 123.286 369.611 123.386C369.098 123.489 368.527 123.621 368.034 123.775L368.027 123.778ZM367.862 121.135C367.862 120.927 367.695 120.76 367.488 120.76C367.281 120.76 367.115 120.927 367.115 121.135C367.115 121.344 367.281 121.513 367.488 121.513C367.695 121.513 367.862 121.345 367.862 121.135Z"
      fill="black"
    />
    <path
      d="M370.402 124.302C370.574 124.387 370.556 124.639 370.488 124.819C370.267 125.398 368.429 126.782 367.889 127.1C367.903 126.827 367.917 126.558 367.929 126.301C368.622 126.135 369.041 125.817 369.571 125.338C369.448 125.182 369.495 124.953 369.571 124.77C369.6 124.694 369.637 124.616 369.702 124.563C369.744 124.528 369.8 124.508 369.853 124.512C369.832 124.598 369.853 124.694 369.927 124.741C370 124.708 369.997 124.584 369.93 124.538C369.907 124.523 369.881 124.514 369.855 124.512C369.863 124.477 369.879 124.443 369.9 124.417C369.977 124.321 370.106 124.283 370.227 124.276C370.286 124.272 370.348 124.276 370.402 124.3V124.302Z"
      fill="black"
    />
    <path
      d="M369.71 135.286C370.001 135.365 370.331 135.492 370.419 135.781C368.304 136.129 365.457 135.714 363.353 135.298C363.353 135.298 363.356 135.298 363.358 135.298C363.435 135.286 364.056 135.167 364.328 134.806C364.552 134.504 365.422 134.582 365.925 134.592C366.634 134.605 367.334 134.744 368.027 134.894C368.098 134.91 368.169 134.924 368.24 134.94C368.607 135.019 368.975 135.1 369.342 135.192C369.465 135.222 369.587 135.254 369.71 135.287V135.286Z"
      fill="black"
    />
    <path
      d="M369.928 124.54C369.994 124.586 369.998 124.71 369.924 124.743C369.852 124.695 369.831 124.6 369.851 124.514C369.879 124.514 369.903 124.524 369.926 124.54H369.928Z"
      fill="black"
    />
    <path
      d="M367.489 120.76C367.696 120.76 367.862 120.927 367.862 121.136C367.862 121.344 367.696 121.513 367.489 121.513C367.282 121.513 367.115 121.345 367.115 121.136C367.115 120.926 367.282 120.76 367.489 120.76Z"
      fill="black"
    />
    <path
      d="M367.734 118.525C367.824 118.833 367.829 119.172 367.741 119.481C367.318 119.451 366.885 119.534 366.464 119.618C366.007 119.708 365.538 119.805 365.11 119.98C365.109 119.98 365.107 119.976 365.103 119.975C364.963 119.943 364.498 118.768 364.489 118.623C364.444 117.928 364.572 117.221 364.858 116.586C364.884 116.528 364.914 116.468 364.97 116.443C365.017 116.42 365.075 116.429 365.126 116.439C365.772 116.561 366.385 116.856 366.885 117.284C367.271 117.614 367.596 118.036 367.736 118.525H367.734Z"
      fill="black"
    />
    <path
      d="M366.462 119.618C366.883 119.534 367.316 119.451 367.739 119.481C367.688 119.661 367.604 119.83 367.486 119.976C367.486 119.976 366.113 120.839 365.106 119.982C365.536 119.807 366.004 119.71 366.46 119.62L366.462 119.618Z"
      fill="black"
    />
    <path
      d="M366.141 120.47L365.292 122.076C365.215 122.273 364.985 122.36 364.778 122.402C363.869 122.591 362.884 122.369 362.14 121.808C361.398 121.245 360.914 120.354 360.844 119.422C360.812 119.001 360.921 118.491 361.314 118.341C361.479 118.279 361.665 118.295 361.84 118.318C362.717 118.433 363.576 118.678 364.38 119.043C365.08 119.359 365.767 119.796 366.141 120.47ZM365.664 120.885C365.664 120.885 365.881 120.708 365.881 120.555C365.881 120.402 362.914 118.385 361.605 118.701C360.297 119.017 362.061 121.513 363.156 121.783C364.452 122.104 365.395 121.599 365.395 121.599C365.485 121.361 365.574 121.123 365.664 120.885Z"
      fill="black"
    />
    <path
      d="M365.664 120.885L365.395 121.599C365.395 121.599 364.452 122.104 363.156 121.783C362.061 121.513 360.297 119.017 361.605 118.701C362.914 118.385 365.881 120.403 365.881 120.555C365.881 120.707 365.664 120.885 365.664 120.885Z"
      fill="black"
    />
    <path
      d="M365.664 120.885L365.395 121.599C365.395 121.599 364.452 122.104 363.156 121.783C362.061 121.513 360.297 119.017 361.605 118.701C362.914 118.385 365.881 120.403 365.881 120.555C365.881 120.707 365.664 120.885 365.664 120.885Z"
      stroke="black"
    />
    <path
      d="M366.661 127.964C367.459 128.4 368.199 128.234 368.997 128.67C369.101 128.726 369.213 128.794 369.248 128.906C369.267 128.97 369.358 129.224 369.348 129.287C369.237 130.041 369.643 130.336 370.144 129.501C370.195 129.416 370.339 129.118 370.313 129.023C370.214 128.647 369.837 128.455 369.523 128.229C368.657 127.604 367.631 127.183 366.661 126.742"
      fill="black"
    />
    <path
      d="M524.274 106.691C526.402 106.116 528.115 104.203 528.302 102.051C531.018 104.453 535.461 104.348 538.484 102.327C541.507 100.307 543.192 96.7487 543.581 93.185C544.339 86.2354 539.092 85.1968 537.17 86.9334C541.191 81.3361 534.821 72.6047 528.273 75.9067C526.764 76.6667 525.299 78.9251 525.299 80.7254C522.239 73.108 511.107 73.4637 513.809 84.2069C510.265 84.5659 508.529 86.6414 507.988 89.1565C507.446 91.6716 507.948 94.445 509.589 96.45C511.23 98.4567 514.056 99.5557 516.602 98.9248C515.519 103.836 519.896 107.876 524.272 106.695L524.274 106.691Z"
      fill="#CE1462"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M481.823 69.8751C481.856 67.9727 481.013 65.9615 479.327 65.0256C477.642 64.0914 475.132 64.6427 474.372 66.3903C474.325 62.868 472.572 59.2947 469.503 57.474C466.434 55.6533 462.085 55.9289 459.593 58.4592C457.1 60.9911 457.152 65.6535 459.977 67.823C457.592 66.4991 454.487 66.3443 452.131 67.7209C449.777 69.0975 448.418 72.0786 449.242 74.6463C450.067 77.214 453.203 78.9105 455.765 77.9576C452.71 79.6268 451.823 84.1803 454.038 86.8398C456.253 89.4994 460.971 89.5488 463.242 86.9351C463.162 89.7291 465.927 91.931 468.729 92.4159C471.859 92.9587 475.235 91.8561 477.412 89.5811C479.589 87.3061 480.502 83.9284 479.757 80.8928L480.081 76.5351C480.983 74.8522 481.788 71.7741 481.821 69.8734L481.823 69.8751Z"
      fill="#CE1462"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M476.863 57.8622C480.077 59.8802 484.447 59.8802 487.66 57.8605C487.706 62.0906 491.646 65.6254 495.85 66.0625C500.053 66.4995 504.216 64.3482 507.094 61.2503C510.665 65.0771 516.905 65.7739 521.56 63.3864C526.217 60.9989 529.217 55.9691 529.814 50.7671C530.587 44.0163 526.762 41.0737 523.757 41.1901C526.328 38.6946 527.934 35.1259 527.714 31.5472C527.495 27.9684 525.317 24.4893 522.036 23.0483C518.755 21.609 514.509 22.4949 512.333 25.3413C513.191 20.4533 509.999 15.1771 505.273 13.6737C502.179 12.69 498.691 13.4054 496.054 15.275C488.965 7.36831 476.819 9.19396 471.928 17.7047C469.368 22.1574 469.333 27.9566 471.837 32.4415C468.652 31.7075 465.083 33.1333 463.277 35.8599C461.473 38.5866 461.554 42.4319 463.472 45.081C465.391 47.7283 469.018 49.0022 472.169 48.1316C471.749 51.9061 473.649 55.8425 476.863 57.8622Z"
      fill="#CE1462"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M523.807 88.9728C516.49 87.7287 506.893 88.594 500.69 92.6073C500.812 81.6093 500.957 57.1438 501.841 55.6152C502.421 54.6118 505.803 52.9604 518.581 47.469C508.725 47.8275 502.803 48.4605 498.704 49.8696C498.704 49.8696 498.376 43.5011 497.719 41.1291C497.719 41.1291 491.331 29.6799 477.753 26.0908C484.414 31.1934 490.28 37.2504 491.806 41.1291C493.613 47.5582 494.078 68.5996 492.299 80.468C484.221 75.8839 470.618 74.6533 461.214 77.1516C480.006 79.8333 487.422 84.5385 490.087 86.2489C491.444 91.104 489.723 122.125 488.803 128.187C488.327 131.313 487.824 133.441 490.087 134.109C493.411 135.091 497.188 136.421 499.798 134.109C501.988 132.172 501.149 130.987 500.69 127.236C499.303 115.893 501.171 100.685 501.171 100.685C502.103 98.9337 514.049 92.1595 523.809 88.9728H523.807Z"
      fill="black"
    />
    <path
      d="M469.69 136.297C470.742 135.983 528.31 136.297 528.31 136.297L525.862 133.729C525.862 133.729 499.177 136.687 496.375 126.851C493.574 117.015 492.264 126.851 492.264 126.851C492.264 126.851 492.522 129.553 487.885 131.027C483.248 132.502 471 134.68 470.732 134.766C470.465 134.851 469.681 136.297 469.681 136.297H469.69Z"
      fill="black"
    />
    <path
      d="M510.794 130.09V134.532L513.681 132.316"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M481.214 119.677C481.214 119.677 485.029 123.89 483.212 126.662C481.395 129.434 480.737 129.772 480.737 129.772C480.737 129.772 477.078 126.395 478.5 123.267C479.922 120.139 481.221 119.671 481.221 119.671L481.214 119.677Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M480.717 134.732C480.613 134.036 480.505 127.89 481.527 122.868"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M470.103 124.04C470.103 124.04 475.787 124.464 476.398 127.717C477.01 130.969 476.768 131.666 476.768 131.666C476.768 131.666 471.778 131.741 470.634 128.501C469.49 125.262 470.104 124.031 470.104 124.031L470.103 124.04Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M480.198 135.266C479.64 134.834 475.294 130.465 472.545 126.132"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M490.911 17.6162C491.848 18.0001 492.287 19.0593 491.903 19.9777C491.518 20.896 490.451 21.3377 489.514 20.9538C488.577 20.5699 488.138 19.5107 488.522 18.5923C488.907 17.674 489.974 17.2323 490.911 17.6162Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M480.224 16.861C481.16 17.2449 481.6 18.3041 481.215 19.2224C480.831 20.1408 479.763 20.5824 478.826 20.1985C477.89 19.8146 477.45 18.7554 477.835 17.8371C478.219 16.9187 479.287 16.4771 480.224 16.861Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M503.124 20.6372C504.061 21.0211 504.5 22.0803 504.116 22.9987C503.731 23.917 502.664 24.3587 501.727 23.9748C500.79 23.5909 500.351 22.5317 500.735 21.6133C501.12 20.695 502.187 20.2533 503.124 20.6372Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M512.284 30.4553C513.221 30.8392 513.66 31.8984 513.276 32.8168C512.891 33.7352 511.824 34.1768 510.887 33.7929C509.95 33.409 509.511 32.3498 509.895 31.4314C510.28 30.5131 511.347 30.0714 512.284 30.4553Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M519.154 53.8679C520.091 54.2518 520.53 55.311 520.146 56.2294C519.761 57.1477 518.694 57.5894 517.757 57.2055C516.82 56.8216 516.381 55.7624 516.766 54.844C517.15 53.9257 518.218 53.484 519.154 53.8679Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M532.895 91.6302C533.831 92.0142 534.271 93.0733 533.886 93.9917C533.502 94.9101 532.434 95.3517 531.497 94.9678C530.561 94.5839 530.121 93.5247 530.506 92.6064C530.89 91.688 531.958 91.2463 532.895 91.6302Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M524.498 96.1616C525.435 96.5455 525.874 97.6047 525.49 98.5231C525.105 99.4414 524.038 99.8831 523.101 99.4992C522.164 99.1153 521.725 98.0561 522.109 97.1377C522.494 96.2194 523.561 95.7777 524.498 96.1616Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M532.895 82.5673C533.831 82.9512 534.271 84.0104 533.886 84.9287C533.502 85.8471 532.434 86.2887 531.497 85.9048C530.561 85.5209 530.121 84.4617 530.506 83.5434C530.89 82.625 531.958 82.1834 532.895 82.5673Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M471.827 83.3225C472.764 83.7064 473.203 84.7656 472.819 85.684C472.434 86.6023 471.367 87.044 470.43 86.6601C469.493 86.2762 469.054 85.217 469.438 84.2986C469.823 83.3803 470.89 82.9386 471.827 83.3225Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M466.483 65.1967C467.42 65.5806 467.86 66.6397 467.475 67.5581C467.091 68.4765 466.023 68.9181 465.086 68.5342C464.149 68.1503 463.71 67.0911 464.095 66.1728C464.479 65.2544 465.547 64.8127 466.483 65.1967Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M458.087 71.2386C459.024 71.6225 459.463 72.6817 459.079 73.6001C458.694 74.5185 457.626 74.9601 456.69 74.5762C455.753 74.1923 455.314 73.1331 455.698 72.2148C456.083 71.2964 457.15 70.8547 458.087 71.2386Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M481.75 41.0288C482.687 41.4127 483.126 42.4719 482.742 43.3903C482.357 44.3086 481.29 44.7503 480.353 44.3664C479.416 43.9825 478.977 42.9233 479.361 42.0049C479.746 41.0866 480.813 40.6449 481.75 41.0288Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M471.827 38.0078C472.764 38.3917 473.203 39.4509 472.819 40.3693C472.434 41.2876 471.367 41.7293 470.43 41.3454C469.493 40.9615 469.054 39.9023 469.438 38.9839C469.823 38.0656 470.89 37.6239 471.827 38.0078Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M507.231 36.7332C508.485 37.2475 509.076 38.6673 508.559 39.9003C508.043 41.1333 506.611 41.7243 505.356 41.2101C504.102 40.6959 503.511 39.276 504.027 38.0431C504.544 36.8101 505.976 36.219 507.231 36.7332Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M498.07 27.6701C499.325 28.1843 499.915 29.6042 499.399 30.8372C498.883 32.0702 497.451 32.6612 496.196 32.147C494.941 31.6328 494.351 30.2129 494.867 28.9799C495.384 27.747 496.816 27.1559 498.07 27.6701Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M486.62 48.8172C487.875 49.3314 488.465 50.7513 487.949 51.9843C487.433 53.2173 486.001 53.8083 484.746 53.2941C483.491 52.7799 482.901 51.36 483.417 50.127C483.933 48.8941 485.365 48.303 486.62 48.8172Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M517.917 37.4885C519.172 38.0027 519.762 39.4226 519.246 40.6556C518.73 41.8886 517.298 42.4796 516.043 41.9654C514.788 41.4512 514.198 40.0313 514.714 38.7983C515.23 37.5653 516.662 36.9743 517.917 37.4885Z"
      fill="white"
      stroke="black"
    />
  </svg>
);
