import React, { useState } from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { Button } from "ancient/components/Button";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

import { SidebarLayout } from "ancient/components/layouts/SidebarLayout";
import { getInvoiceDetail, getPaginatedInvoicesList } from "lib/api/invoices";
import { format } from "date-fns";
import { ca, es } from "date-fns/locale";
import { useApplicationContext } from "hooks/useApplicationContext";
import { PaginatedResourceList } from "ancient/components/PaginatedResourceList";
import { capitalize } from "lib/helpers/string";

const getDateLocale = (locale) => {
  return {
    es,
    ca,
  }[locale];
};

const useStyles = makeStyles((theme) => ({
  amount: {
    fontSize: theme.typography.h5.fontSize,
    display: "flex",
    justifyContent: "flex-end",
  },
  date: {
    [theme.breakpoints.down("md")]: {
      fontSize: theme.typography.h6.fontSize,
    },
    display: "flex",
    fontSize: theme.typography.h5.fontSize,
  },
  number: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.grey["600"],
  },
}));

const Invoice = ({ invoice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const { currentUser } = useApplicationContext();
  const { t } = useTranslation();
  const classes = useStyles();

  const onClickDownload = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    await getInvoiceDetail({ id: invoice.id })
      .then(async ({ data }) => {
        // Create a Blob from the response data
        const base64Pdf = await fetch(
          `data:application/pdf;base64,${data.pdf}`
        );
        const pdfBlob = await base64Pdf.blob();
        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(pdfBlob);
        // Create a temporary <a> element to trigger the download
        const tempLink = document.createElement("a");
        tempLink.href = url;
        // Set the filename for the downloaded file
        tempLink.setAttribute("download", `${data.name}.pdf`);

        // Append the <a> element to the body and click it to trigger the download
        document.body.appendChild(tempLink);
        tempLink.click();

        // Clean up the temporary elements and URL
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        setHasErrors(true);
      });
    setIsLoading(false);
  };

  return (
    <Box component={Card} display="flex" flex={1} p={2} width="100%">
      <Box display="flex" width="100%" flexDirection="column" key={invoice.id}>
        <Box
          pl={1}
          display="flex"
          width="100%"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <div className={classes.date}>
            {capitalize(
              format(new Date(invoice.date), "MMMM yyyy", {
                locale: getDateLocale(currentUser.preferred_locale),
              })
            )}
          </div>
          <div className={classes.amount}>
            {invoice.total_amount.toLocaleString(currentUser.preferred_locale, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            €
          </div>
        </Box>
        <Box
          display="flex"
          width="100%"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            width="100%"
            alignItems="baseline"
            justifyContent="space-between"
            flexDirection="column"
          >
            <div className={classes.number}>{invoice.name}</div>
            <div className={classes.number}>
              {t(`invoices.status.${invoice.status}`)}
            </div>
          </Box>

          <div>
            {!hasErrors && (
              <Button
                variant="text"
                onClick={onClickDownload}
                href={invoice.download_url}
                color="primary"
                disabled={isLoading}
                fullWidth={false}
              >
                {isLoading ? t("common.loading") : t("common.download")}
              </Button>
            )}
          </div>
        </Box>
        {hasErrors && (
          <Alert severity="error">{t("invoices.download_error")}</Alert>
        )}
      </Box>
    </Box>
  );
};

export const Invoices = () => {
  const { t } = useTranslation();

  return (
    <SidebarLayout>
      <PaginatedResourceList
        paginatorQuery={(offset) => getPaginatedInvoicesList({ offset })}
        queryKey="invoices"
        renderItem={(invoice) => <Invoice invoice={invoice} />}
        renderHeader={() => (
          <Box mb={2}>
            <Typography variant="h5" component="h1">
              {t("invoices.description")}
            </Typography>
          </Box>
        )}
      />
    </SidebarLayout>
  );
};
