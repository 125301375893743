import { Typography } from "@mui/material";
import { Footer } from "components/layouts/Footer";
import { useQueryParams } from "hooks/useQueryParams";
import { getUrlQuery, openUrl } from "lib/helpers/urls";

import { Trans } from "react-i18next";

export const SetTariffFooter = () => {
  const queryParams = useQueryParams();

  return (
    <Footer>
      <Typography variant="body_small">
        <Trans i18nKey={"funnel.tariffs.internet_and_mobile.footer"}>
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            target="_parent"
            onClick={() =>
              openUrl(
                getUrlQuery("/tariffs/personalized", queryParams),
                "_parent"
              )
            }
          />
        </Trans>
      </Typography>
    </Footer>
  );
};
