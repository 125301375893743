import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import { InputLabel } from "./InputLabel";
import { InputErrorLabel } from "./InputErrorLabel";
import { InputSubInfo } from "./InputSubInfo";
import { InputAdornment } from "./InputAdornment";
import { StyledInput } from "./StyledInput";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { IMaskInput } from "react-imask";

const MaskedInput = (props) => {
  const {
    onChange,
    placeholder,
    showPlaceHolderMask = false,
    mask,
    ...other
  } = props;
  const [hasBeenFocused, setHasBeenFocused] = useState(false);

  return (
    <IMaskInput
      {...other}
      placeholder={placeholder}
      mask={mask}
      onFocus={(...args) => {
        setHasBeenFocused(true);
        props.onFocus && props.onFocus(...args);
      }}
      lazy={!(showPlaceHolderMask && hasBeenFocused)}
      mapToRadix={["/", ".", "-", " "]}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
};

export const InputField = React.forwardRef(
  (
    {
      label,
      questionLabel,
      infoLabel,
      startIconAdorment,
      endIconAdorment,
      errorLabel,
      id,
      optional,
      ...props
    },
    ref
  ) => {
    const isError = Boolean(errorLabel);
    return (
      <FormControl {...props}>
        <InputLabel
          id={id}
          label={label}
          questionLabel={questionLabel}
          optional={optional}
        />
        <StyledInput
          id={id}
          startAdornment={
            startIconAdorment && (
              <InputAdornment
                position="start"
                iconAdorment={startIconAdorment}
              />
            )
          }
          endAdornment={
            (endIconAdorment || isError) && (
              <InputAdornment
                position="end"
                iconAdorment={endIconAdorment}
                isError={isError}
              />
            )
          }
          disableUnderline
          isError={isError}
          inputRef={ref}
          inputComponent={props.mask ? MaskedInput : undefined}
          inputProps={props}
          {...props}
        />
        {errorLabel && <InputErrorLabel label={errorLabel} />}
        {infoLabel && <InputSubInfo id={id} label={infoLabel} />}
      </FormControl>
    );
  }
);

const errorText = (t, meta) => {
  const error = meta.error || meta.submitError;

  const [errorKey, errorTextInterpolationValue] = Array.isArray(error)
    ? error
    : [error];

  return (
    meta.submitFailed &&
    error &&
    t(`common.errors.validation.${errorKey}`, errorTextInterpolationValue)
  );
};

const FormField = React.forwardRef(({ name, validate, ...props }, ref) => {
  const { t } = useTranslation();
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <InputField
          {...props}
          name={input.name}
          value={input.value}
          onChange={input.onChange}
          ref={ref}
          errorLabel={errorText(t, meta)}
        />
      )}
    </Field>
  );
});

InputField.FormField = FormField;
