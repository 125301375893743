import { Alert, Typography, AlertTitle } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";

const ICONS = {
  doubt: HelpIcon,
  info: InfoIcon,
  success: CheckIcon,
  warning: WarningIcon,
  error: ErrorIcon,
};

const ALERT_STYLES = {
  doubt: () => ({
    backgroundColor: "sand.main",
    color: "grey.900",
  }),
  info: () => ({
    backgroundColor: "grey.100",
    color: "grey.900",
  }),
  success: () => ({
    backgroundColor: "green.main",
    color: "grey.900",
  }),
  warning: () => ({
    backgroundColor: "secondary.main",
    color: "grey.900",
  }),
  error: () => ({
    backgroundColor: "error.main",
    color: "grey.900",
  }),
};

export const AlertComponent = ({ sx, size = "normal", ...props }) => {
  const Icon = ICONS[props.type] || null;
  const alertStyles = ALERT_STYLES[props.type] || (() => ({}));

  return (
    <Alert
      icon={
        Icon && (
          <Icon
            sx={{
              color: "grey.900",
              fontSize: size === "normal" ? "32px" : "16px",
            }}
          />
        )
      }
      sx={{
        ...alertStyles(),
        ...sx,
      }}
    >
      {props.title && (
        <AlertTitle
          sx={{
            mb: 0,
            fontWeight: 600,
            fontSize: size === "normal" ? "16px" : "12px",
            lineHeight: size === "normal" ? "24px" : "16px",
          }}
        >
          {props.title}
        </AlertTitle>
      )}
      {props.text && <Typography variant="body_small">{props.text}</Typography>}
    </Alert>
  );
};
