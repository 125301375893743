import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Typography } from "@mui/material";
import { Button } from "components/Button";
import { WLandlineChip, WOPermanenceChip } from "components/Chips";
import { Divider } from "components/Divider";
import { SuperCard } from "components/SuperCard";
import { useTranslation } from "react-i18next";
import { Price } from "../PricePreview";
import { TariffInfo } from "../TariffInfo";
import { PackTariffTitle } from "screens/Tariffs/components/TariffsTitle";

export const CardContractInfo = ({
  pack,
  onContract,
  showInfo = false,
  showContract = false,
}) => {
  const { t } = useTranslation();
  const fiberName = pack.products.find(
    ({ category }) => category === "fiber"
  ).name;
  const mobileName = pack.products.find(
    ({ category }) => category === "mobile"
  ).name;
  return (
    <SuperCard sx={{ gap: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography variant="body_xl_bold">
          <PackTariffTitle
            mobilesInPack={pack.mobiles_in_pack}
            fiberBandwidth={pack.fiber_bandwidth}
          />
        </Typography>
        {showInfo && (
          <TariffInfo
            type="pack"
            mobilesInPack={pack.mobiles_in_pack}
            hasLandline={pack.has_landline_phone}
            title={
              <PackTariffTitle
                mobilesInPack={pack.mobiles_in_pack}
                fiberBandwidth={pack.fiber_bandwidth}
                addLandlineInfo={true}
                hasLandline={pack.has_landline_phone}
              />
            }
          />
        )}
      </Box>

      <Box sx={{ display: "flex", alignItems: "flex-start", gap: "4px" }}>
        {pack.has_landline_phone && <WLandlineChip />}
        <WOPermanenceChip />
      </Box>
      <Typography variant="body">{fiberName}</Typography>
      <Typography variant="body">{mobileName}</Typography>
      {/* All products in pack is unlimited */}
      <Typography variant="body">
        {t("funnel.tariffs.internet_and_mobile.contract_info.unlimited_calls")}
      </Typography>
      <Divider flexItem />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Price price={parseFloat(pack.price).toFixed(2)} stackingPrice={true} />
      </Box>
      {showContract && (
        <Button fullWidth onClick={onContract} endIcon={<ArrowForwardIcon />}>
          {t("common.contract")}
        </Button>
      )}
    </SuperCard>
  );
};
