import React, { useEffect, useMemo, useState } from "react";
import { BandwithSelector } from "./BandwithSelector";
import { InternetTariffInfo } from "./InternetTariffInfo";
import { TechnologySelector } from "./TechnologySelector";
import { getTechnologicalOffers } from "screens/Tariffs/utils/tariffsReducers";
import { Field } from "react-final-form";

export const InternetTariffPicker = ({
  internetTariffs,
  onChange,
  bandwidthAction,
  initialTariffCode,
  ...props
}) => {
  const initialTariff = useMemo(() => {
    return internetTariffs.find(({ code }) => code === initialTariffCode);
  }, [internetTariffs]);
  const [technologicalOffers, setTechnologicalOffers] = useState(
    [1, 2, 0].map(
      (index) => getTechnologicalOffers(internetTariffs, initialTariff)[index]
    )
  );
  const [selectTechnoOffer, setSelectTechnoOffer] = useState(
    technologicalOffers.find(({ isSelected }) => isSelected) || {
      bandwidths: [],
    }
  );

  function setTechnologicalOfferSelected(technologicalOffer) {
    const technologicalOffersCopy = [...technologicalOffers];
    technologicalOffersCopy.forEach(
      (item) => (item.isSelected = item.name === technologicalOffer.name)
    );
    if (technologicalOffer.bandwidths.length === 1) {
      technologicalOffer.bandwidths[0].isSelected = true;
    }
    setTechnologicalOffers(technologicalOffersCopy);
    setSelectTechnoOffer(technologicalOffer);
  }

  function setBandwidthSelected(bandwidth) {
    const selectTechnoOfferCopy = { ...selectTechnoOffer };
    selectTechnoOfferCopy.bandwidths.forEach(
      (item) => (item.isSelected = item.bandwidth === bandwidth.bandwidth)
    );
    setSelectTechnoOffer(selectTechnoOfferCopy);
  }

  const matchingTariff = useMemo(() => {
    const category = selectTechnoOffer.category;
    const hasLandLine = selectTechnoOffer.hasLandLine;
    const bandwidth = selectTechnoOffer.bandwidths.find(
      (bandwidth) => bandwidth.isSelected
    )?.bandwidth;
    return internetTariffs.find(
      (tariff) =>
        tariff.category === category &&
        tariff.has_landline_phone === hasLandLine &&
        tariff.bandwidth === bandwidth
    );
  }, [selectTechnoOffer]);

  useEffect(() => {
    onChange(matchingTariff);
  }, [matchingTariff]);

  return (
    <>
      <TechnologySelector
        onClick={setTechnologicalOfferSelected}
        technologies={technologicalOffers}
      />
      {selectTechnoOffer.bandwidths.length > 1 && (
        <BandwithSelector
          onClick={setBandwidthSelected}
          bandwidths={selectTechnoOffer.bandwidths}
          bandwidthAction={bandwidthAction}
          tech={selectTechnoOffer.category}
        />
      )}
      {matchingTariff && <InternetTariffInfo {...matchingTariff} {...props} />}
    </>
  );
};

InternetTariffPicker.FormField = ({ name, validate, ...props }) => (
  <Field name={name} validate={validate}>
    {({ input, meta }) => (
      <InternetTariffPicker
        {...props}
        initialTariffCode={input.value}
        onChange={({ code }) => input.onChange(code)}
      />
    )}
  </Field>
);
