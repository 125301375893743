import { Box, Typography } from "@mui/material";
import { LowerDialogRight } from "components/icons/Dialogs";

export const DialogSuccesForm = ({ text }) => {
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Rock Salt",
          fontSize: "19px",
          fontWeight: "400",
          lineHeight: "18px",
          letterSpacing: "-0.26px",
          textAlign: "center",
          position: "absolute",
          zIndex: 1,
          top: "85px",
          left: "46px",
        }}
      >
        {text}
      </Typography>
      <LowerDialogRight minX="-5" minY="-5" />
    </Box>
  );
};
