import { Typography } from "@mui/material";
import { Footer } from "components/layouts/Footer";
import { useState } from "react";
import { Trans } from "react-i18next";
import { ModalHighlights } from "screens/Faqs/highlights/ModalHighlights";

export const FaqsFooter = ({ ...props }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <ModalHighlights
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        {...props}
      />
      <Footer>
        <Typography variant="body">
          <Trans i18nKey={"faqs.tariffs-cta"}>
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => setIsModalOpen((isOpen) => !isOpen)}
            />
          </Trans>
        </Typography>
      </Footer>
    </>
  );
};
