import {
  styled,
  InputLabel as MaterialInputLabel,
  Typography,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import React from "react";

const StyledInputLabel = styled(MaterialInputLabel)(({ theme }) => ({
  "transform": "translate(0, -12px)",
  "color": theme.palette.grey[900],
  "&.Mui-focused": { color: theme.palette.grey[900] },
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "space-between",
  "gap": "4px",
  "width": "100%",
  "alignSelf": "stretch",
  "mt": "6px",
  "&.Mui-disabled": { color: theme.palette.grey[900] },
  "&.Mui-error": { color: theme.palette.grey[900] },
  "& .MuiInputLabel-asterisk": {
    display: "none",
  },
}));
/**
 * TODO implement tooltip in hekp icon ?
 */

export const InputLabel = ({ id, label, questionLabel, optional }) => (
  <StyledInputLabel htmlFor={id} shrink>
    <Typography variant="body_small">{label}</Typography>
    {questionLabel && <HelpIcon />}
    {optional && (
      <Typography
        variant="body_small_bold"
        sx={{
          backgroundColor: "grey.200",
          color: "grey.800",
          padding: "2px 8px",
          borderRadius: "4px",
          fontWeight: "bold",
          fontSize: "0.75rem",
          marginLeft: "auto",
          textTransform: "uppercase",
        }}
      >
        opcional
      </Typography>
    )}
  </StyledInputLabel>
);
