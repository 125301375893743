import { Checkbox, styled, Typography } from "@mui/material";
import {
  Checked,
  Unchecked,
  Indeterminate,
} from "components/icons/CheckBoxIcons";
import { FormControlLabel } from "@mui/material";
import { Field } from "react-final-form";

const StyledInput = styled(Checkbox)(() => ({
  padding: 0,
  marginRight: 10,
}));

const StyledFromControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "alignItems": "center",
  "marginLeft": "0",
  "& .MuiFormControlLabel-label": {
    "&.Mui-disabled": { color: theme.palette.grey[900] },
  },
  "& .MuiFormControlLabel-asterisk": {
    display: "none",
  },
}));

export const CheckField = ({ label, infoLabel, ...props }) => {
  return (
    <StyledFromControlLabel
      control={
        <StyledInput
          {...props}
          icon={<Unchecked />}
          checkedIcon={<Checked />}
          indeterminateIcon={<Indeterminate />}
        />
      }
      label={
        <>
          <Typography variant="body_bold">{label}</Typography>
          {infoLabel && (
            <>
              {label && <br />}
              <Typography variant="body_small">{infoLabel}</Typography>
            </>
          )}
        </>
      }
    />
  );
};

CheckField.FormField = ({ name, validate, initialValue, ...props }) => (
  <Field
    name={name}
    validate={validate}
    initialValue={initialValue}
    type="checkbox"
  >
    {({ input, meta }) => (
      <CheckField
        {...props}
        checked={input.checked}
        onChange={input.onChange}
      />
    )}
  </Field>
);
