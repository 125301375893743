import { Box } from "@mui/material";

export const Image = ({ sx, ...props }) => {
  return (
    <Box
      component="img"
      sx={{
        ...sx,
      }}
      {...props}
    />
  );
};
