import React from "react";
import Box from "@mui/material/Box";

import { BrandLogo } from "components/icons/BrandLogo";
import { useTranslation } from "react-i18next";
import { SuperCard } from "components/SuperCard";

const Backdrop = ({ children }) => (
  <Box
    p={2}
    flex={1}
    bgcolor={process.env.REACT_APP_FORM_BUILD ? "transparent" : "primary.main"}
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    height="99vH"
  >
    {children}
  </Box>
);

const Container = ({ children }) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    flex={1}
    width={{
      xs: "100%",
      sm: "470px",
    }}
    p={2}
  >
    {children}
  </Box>
);

export const FullScreenCenteredLayout = ({ children }) => {
  const { i18n } = useTranslation();
  const locale = i18n.language || "ca";
  return (
    <Backdrop>
      <Container>
        <Box display="flex" alignItems="center" justifyContent="center" m={2}>
          <BrandLogo size="large" color="white" locale={locale} />
        </Box>
        <SuperCard>{children}</SuperCard>
      </Container>
    </Backdrop>
  );
};
