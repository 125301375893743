import { Modal } from "components/layouts/Modal";
import { useTranslation } from "react-i18next";
import { Highlights } from "./Highlights";

export const ModalHighlights = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <Modal title={t("tariff_info.modal.title")} {...props}>
      <Highlights {...props} sx={{ px: 3, height: "100%" }} />
    </Modal>
  );
};
